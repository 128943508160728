<template>
    <mhospital style="margin-bottom:10px" ref="main0" title="设备" table_name="user_saihong_gprs" table_show_header>
        <!-- 弹出编辑模态窗口 -->
        <template #writeForm="{writeForm}">

            <el-tabs tab-position="left">
                <el-tab-pane label="设备绑定">
                    <el-row>
                        <el-form-item label="设备识别码" prop="imei">
                            <el-input v-model="writeForm.imei" disabled/>
                        </el-form-item>

                    </el-row>
                    <el-row>
                        <el-form-item label="S/N" prop="sn">
                            <el-input v-model="writeForm.sn" disabled/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item prop="uid" label="绑定患者">
                            <el-select v-model="writeForm.uid" placeholder="请选择" clearable filterable size="small">
                                <el-option value="0" label="请选择"/>
                                <el-option v-for="item in user_opt" :key="item.id" :label="item.name+' '+item.mobile"
                                           :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item prop="note" label="设备编号">
                            <el-input clearable v-model="writeForm.note" placeholder=""/>
                        </el-form-item>
                    </el-row>

                </el-tab-pane>
                <el-tab-pane label="配置参数">
                    <el-row>
                        <el-form-item prop="is_voice" label="语音播报">
                            <el-radio-group v-model="writeForm.is_voice">
                                <el-radio-button label="1">关闭</el-radio-button>
                                <el-radio-button label="0">开启</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item prop="day_i" label="日间频率">
                            <el-select v-model="writeForm.day_i" placeholder="请选择" style="width:80px">
                                <el-option label="15" value="15"></el-option>
                                <el-option label="20" value="20"></el-option>
                                <el-option label="30" value="30"></el-option>
                                <el-option label="60" value="60"></el-option>
                                <el-option label="90" value="90"></el-option>
                                <el-option label="120" value="120"></el-option>
                            </el-select>
                            分钟
                        </el-form-item>&nbsp;&nbsp;&nbsp;&nbsp;
                        <el-form-item prop="night_i" label="夜间频率">
                            <el-select v-model="writeForm.night_i" placeholder="请选择" style="width:80px">
                                <el-option label="15" value="15"></el-option>
                                <el-option label="20" value="20"></el-option>
                                <el-option label="30" value="30"></el-option>
                                <el-option label="60" value="60"></el-option>
                                <el-option label="90" value="90"></el-option>
                                <el-option label="120" value="120"></el-option>
                            </el-select>
                            分钟
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item prop="night_start" label="夜间开始">
                            <el-input clearable v-model="writeForm.night_start" placeholder="" style="width:80px"/>
                            点
                        </el-form-item>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <el-form-item prop="night_end" label="夜间结束">
                            <el-input clearable v-model="writeForm.night_end" placeholder="" style="width:80px"/>
                            点
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <div>
                            <el-form-item prop="Systolic_alert" label="收缩压阈值">
                                <el-input clearable v-model="writeForm.Systolic_alert" placeholder=""
                                          style="width:80px"/>
                                mmHg
                            </el-form-item>
                            <el-form-item prop="Diastolic_alert" label="舒张压阈值">
                                <el-input clearable v-model="writeForm.Diastolic_alert" placeholder=""
                                          style="width:80px"/>
                                mmHg
                            </el-form-item>
                        </div>
                        <div style="font-size: 10px;width: 480px;padding-left: 68px;color: #888888">
                            备注：一级高血压收缩压140-159mmHg，舒张压90~99mmHg；二级高血压收缩压160-179mmHg，舒张压100~109mmHg；三级高血压收缩压≥180mmHg，舒张压≥110mmHg。
                        </div>
                    </el-row>

                </el-tab-pane>
            </el-tabs>
        </template>
        <!-- 搜索框 -->
        <template #searchForm="{searchForm}">
            <el-form-item label="设备序列号" prop="imei">
                <el-input v-model="searchForm.imei" clearable/>
            </el-form-item>
            <el-form-item label="设备编号" prop="note">
                <el-input v-model="searchForm.note" clearable/>
            </el-form-item>

            <el-form-item prop="hospital_id" label="社区医院" v-if="hrole==23||hrole==24">
                <el-select v-model="searchForm.hospital_id" placeholder="请选择" clearable filterable
                           size="small"
                           @change="search_btn('main0')">
                    <el-option value="" label="全部"/>
                    <el-option v-for="item in hospital_opt" :key="item.id" :label="item.name"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
        </template>
        <template #tableOpt>
            <el-card style="margin-top:10px;" :body-style="{ padding: '20px' }">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <p style="background:#EDF0F599;text-align:center;line-height:50px;margin-bottom:0;color:#526484;font-size:16px">
                            <i class="el-icon-monitor"></i> 设备总数<span
                                style="font-size:24px;margin:0 10px;font-weight:500">{{total}}</span>台</p>
                    </el-col>
                    <el-col :span="8">
                        <p style="background:#EDF0F599;text-align:center;line-height:50px;margin-bottom:0;color:#00A3B0;font-size:16px">
                            <i class="el-icon-refresh-right"></i> 已绑定<span
                                style="font-size:24px;margin:0 10px;font-weight:500">{{used}}</span>台</p>
                    </el-col>
                    <el-col :span="8">
                        <p style="background:#EDF0F599;text-align:center;line-height:50px;margin-bottom:0;color:#999999;font-size:16px">
                            <i class="el-icon-video-pause"></i> 未绑定<span
                                style="font-size:24px;margin:0 10px;font-weight:500">{{total-used}}</span>台</p>
                    </el-col>
                </el-row>
            </el-card>
        </template>
        <!-- 列表 -->
        <template #table>
            <el-table-column label="序号" width="60" type="index" align="center"/>
            <el-table-column v-if="hrole=='1'" label="社区医院" width="160" prop="hospital.name" align="center"/>
            <el-table-column label="设备识别码" width="160" align="center">
                <template slot-scope="scope">
                    <el-link type="primary" @click="goto_report_by_imei(scope.row.imei)">{{scope.row.imei}}</el-link>
                </template>
            </el-table-column>
            <el-table-column label="S/N" width="160" prop="sn" align="center"/>
            <el-table-column label="采集频率" prop="day_i" align="center">
                <template slot-scope="scope">
                    日间{{scope.row.day_i}}分钟/夜间{{scope.row.night_i}}分钟
                </template>
            </el-table-column>
            <el-table-column label="夜间时段" width="100" align="center">
                <template slot-scope="scope">
                    {{scope.row.night_start}}点-{{scope.row.night_end}}点
                </template>
            </el-table-column>
            <el-table-column label="收缩压阈值" prop="Systolic_alert" width="90" align="center"/>
            <el-table-column label="舒张压阈值" prop="Diastolic_alert" width="90" align="center"/>
            <el-table-column label="语音" width="80" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is_voice==0" type="success" size="mini">开启</el-tag>
                    <el-tag v-else type="danger" size="mini">关闭</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="设备编号" prop="note" width="80" align="center"/>
            <el-table-column label="当前绑定用户" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.user&&scope.row.user.id>0">
						{{scope.row.user.name+' '+scope.row.user.mobile}}
					</span>
                    <span v-else>
						<el-tag type="danger" size="mini">未绑定</el-tag>
					</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="最新报告" align="center" width="80">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.report.is_24==1" size="mini">已生成</el-tag>
                    <el-tag type="danger" v-else size="mini">未生成</el-tag>
                </template>
            </el-table-column> -->
            <!-- <el-table-column label="测量开始时间" align="center">
                <template slot-scope="scope">
                    {{scope.row.report.DT}}
                </template>
            </el-table-column> -->

            <el-table-column label="操作" width="140" align="center">
                <template slot-scope="scope">
                    <el-link style="margin-right:10px" icon="el-icon-edit" type="primary"
                             @click="edit_btn('main0', scope.row.id,scope.row.hospital_id)">编辑
                    </el-link>
                    <el-link v-if="scope.row.user&&scope.row.user.id>0" icon="iconfont icon-bangding" type="primary"
                             @click="unbind_btn('main0', scope.row.id)">解绑
                    </el-link>
                </template>
            </el-table-column>
        </template>
    </mhospital>
</template>
<script>
    import mhospital from "../../components/mhospital1.vue";
    import request from "../../plugins/axios";

    export default {
        data() {
            return {
                user_opt: [],
                total: 0,
                used: 0,
                hrole: '',
                hospital_opt: [],
            };
        },
        components: {
            mhospital,
        },
        mounted() {
            this.get_info();
            request({
                url: "/hospital/Read/jk_hospital_opt",
                data: {hp: 2000},
            }).then((ret) => {
                if (ret.code == 1) {
                    this.hospital_opt = ret.data;
                }
            });
            this.hrole = localStorage.getItem('hrole')
            console.log(localStorage.getItem('hrole'))
        },
        methods: {
            get_info() {
                let that = this
                request({
                    url: "/hospital/Read/user_opt",
                    data: {},
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.user_opt = ret.data;
                    }
                });
                request({
                    url: "/hospital/Read/user_saihong_gprs_total",
                    data: {
                        huid: this.$refs.main0.searchForm.hospital_id
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.total = ret.data.total;
                        this.used = ret.data.used;
                    }
                });
            },
            get_user_opt(hospital_id) {
                request({
                    url: "/hospital/Read/user_opt",
                    data: {
                        huid: hospital_id
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.user_opt = ret.data;
                    }
                });
            },
            search_btn(ref_name) {
                console.log(ref_name)
                this.$refs[ref_name].onSearch();
                this.get_info();
            },
            del_btn(ref_name, id) {
                this.$refs[ref_name].onDelete(id);
                this.get_info();
            },
            edit_btn(ref_name, id, hospital_id) {
                this.$refs[ref_name].showWriteForm(id);
                this.get_user_opt(hospital_id)
            },
            unbind_btn(ref_name, id) {

                this.$confirm('确认要解除绑定码?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    request({
                        url: "/hospital/Write/user_saihong_gprs/edit/" + id,
                        data: {
                            uid: 0,
                        },
                    }).then((ret) => {
                        if (ret.code == 1) {
                            this.$notify({
                                title: "提示",
                                message: "已解绑",
                                type: "success",
                            });
                            this.$refs[ref_name].onSearch();
                            this.get_info();
                        }
                    });
                }).catch(() => {
                    this.$notify({
                        title: "提示",
                        message: "取消解绑",
                        type: "info",
                    });
                });
            },
            addsub_btn(ref_name, send_id) {
                this.$refs[ref_name].onAddsub({
                    id: 0,
                    buy_id: send_id,
                    goods_type: "",
                    goods_num: "",
                    client_id: "",
                    note: "",
                });
            },
            deletesub_btn(ref_name, index) {
                this.$refs[ref_name].onDeletesub(index);
            },
            goto_report_by_imei(imei) {
                this.$router.push({name: "/admin/report", params: {imei: imei}});
            },
            go_tab(activeName, oldActiveName) {
                // console.log(activeName);
                // if (activeName == "1") {
                // this.$refs["main2"].writeForm.role = 4;
                // this.$refs["main2"].searchForm.role = 4;
                // } else if (activeName == "0") {
                // this.$refs["main0"].writeForm.role = "";
                // this.$refs["main0"].searchForm.role = "";
                // }
            },
        },
    };
</script>
